const GetData = function(){
    return [
        {
            id:1,
            image:"q1",
            match:11,
            
        },
        {
            id:2,
            image:"q2",
            match:12,
            
        },
        {
            id:3,
            image:"q3",
            match:10,
            
        },
        {
            id:4,
            image:"q4",
            match:16,
            
        },
        {
            id:5,
            image:"q5",
            match:14,
            
        },
        {
            id:6,
            image:"q6",
            match:13,
            
        },
        {
            id:7,
            image:"q7",
            match:15,
            
        },
        {
            id:8,
            image:"q8",
            match:9,
            
        },
        /* {
            image:"a1",
            match:8",
            
        },
        {
            image:"a2",
            match:3",
            
        },
        {
            image:"a3",
            match:1",
            
        },
        {
            image:"a4",
            match:2",
            
        },
        {
            image:"a5",
            match:6",
            
        },
        {
            image:"a6",
            match:5",
            
        },
        {
            image:"a7",
            match:7",
            
        },
        {
            image:"a8",
            match:4",
            
        } */
    ]
}

export default GetData;