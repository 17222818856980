import './Cover.css';

const Cover = function(props){    
    return (
        <div className='cover'>
            
        </div>
    )

}
export default Cover;